var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Pool"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',{staticClass:"grey--text"},[_vm._v("All consultants in the AFG talent pool")])]},proxy:true},{key:"tools",fn:function(){return [_c('SecondaryButton',{on:{"click":function($event){_vm.showInviteConsultantDialog = true}}},[_vm._v("Invite consultant")])]},proxy:true}])},[_c('Modal',{attrs:{"title":"Invite a consultant"},model:{value:(_vm.showInviteConsultantDialog),callback:function ($$v) {_vm.showInviteConsultantDialog=$$v},expression:"showInviteConsultantDialog"}},[_c('invite-consultant-form',{on:{"close":function($event){_vm.showInviteConsultantDialog = false}}})],1),_c('Table',{attrs:{"query":_vm.query,"queryKey":"talentPool","headers":_vm.headers,"exportOptions":{
      headers: [
        {
          text: 'ID',
          value: 'prn',
        },
        {
          text: 'Fullname',
          value: 'name',
        },
        {
          text: 'Phone number',
          value: 'phone',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Role',
          value: 'jobTitle.name',
        },
        {
          text: 'Field',
          value: 'practiceArea',
        },
        {
          text: 'Year Joined',
          value: 'yearJoined',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
    },"searchFilters":[
      {
        field: 'nameContains',
        label: 'Name',
      },
      {
        field: 'practiceArea',
        label: 'Practice area',
      },
      {
        field: 'trainingTagsIn',
        label: 'Training Tags',
      },
    ],"listFilters":[
      {
        field: 'jobTitleId',
        label: 'Role',
        icon: 'la-briefcase',
        queryFilter: {
          query: _vm.allJobTitlesQuery,
          itemVaue: 'id',
          listKey: 'jobTitles',
          per: 50,
          returnObject: true,
        },
      },
      {
        field: 'jobFunctions',
        label: 'Job function',
        icon: 'la-tag-outline',
        filters: _vm.jobFunctionListFilters,
        multiple: true,
      },
      {
        field: 'availability',
        label: 'Availability',
        icon: 'la-circle',
        filters: _vm.availabilityList,
      },
      {
        field: 'countryId',
        label: 'Country',
        queryFilter: {
          query: _vm.countriesQuery,
          itemValue: 'id',
          listKey: 'countries',
          per: 200,
          returnObject: true,
        },
        icon: 'la-flag',
      },
      {
        field: 'regionId',
        label: 'Region',
        queryFilter: {
          query: _vm.regionsQuery,
          itemValue: 'id',
          listKey: 'regions',
          returnObject: true,
        },
        icon: 'la-globe',
      },
      {
        field: 'status',
        label: 'Status',
        filters: _vm.consultantStatuses,
      },
      {
        field: 'gender',
        label: 'Gender',
        filters: {
          Male: 'Male',
          Female: 'Female',
          Other: 'Other',
          'Prefer not to say': 'Prefer not to say',
        },
      },
    ],"dateFilters":[
      {
        field: 'createdAtDate',
        label: 'Added',
      },
    ]},on:{"click:row":_vm.gotoDetailPage},scopedSlots:_vm._u([{key:`item.avatar`,fn:function({ item }){return [(item.user && item.user.avatar)?_c('v-avatar',{attrs:{"size":"36"}},[_c('v-img',{staticClass:"text-center",attrs:{"src":item.user.avatar.url}})],1):_c('v-icon',{attrs:{"x-large":""}},[_vm._v("la-user-circle")])]}},{key:`item.practiceArea`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.practiceArea || "-"))])]}},{key:`item.jobFunctions`,fn:function({ item }){return [_c('span',{staticClass:"truncate",staticStyle:{"display":"block","width":"200px"}},[_vm._v(_vm._s(_vm.jobFunctionList(item.jobFunctions).join(" , ") || "-"))])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }